import { paths } from '@mainApp/src/config/paths';
import type { IAuthRepository } from '@mainApp/src/repositories';
import {
  IReactionDisposer,
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import type { IToastStore } from './Toast.store';
import { IAuthStore, SocialAuthTypes } from './types';
const { hostAuth } = paths;

import type { IRedirectService, IStorageService } from '@mainApp/src/services';
import {
  RedirectionIdsEnum,
  RedirectionURLActionsEnum,
  RedirectionURLQueriesEnum,
  StorageDataKeysEnum,
} from '@mainApp/src/services';

import { IOC_TOKENS } from '@mainApp/src/ioc';
import { inject, injectable } from 'inversify';

import { ApiBase } from './ApiBase';

import { urqlClientService } from '@mainApp/src/config/urql.config';

enableStaticRendering(typeof window === 'undefined');

const AUTH_CALLER_URL = StorageDataKeysEnum.AUTH_CALLER_URL;

const initialAuthForm = {
  show: false,
  email: '',
  processing: false,
  completed: false,
};

@injectable()
export class AuthStore extends ApiBase implements IAuthStore {
  logged = false;
  accessToken: string | null = null;
  loginMode = false;
  authForm = initialAuthForm;
  repository: IAuthRepository;
  redirectService: IRedirectService;
  storageService: IStorageService;
  reactionDisposer: IReactionDisposer;

  get bearerToken() {
    return `Bearer ${this.accessToken}`;
  }

  static authorizeBySocial(type: SocialAuthTypes) {
    global.open(`${hostAuth}/${type}`, '_self');
  }

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.authRepository) repository: IAuthRepository,
    @inject(IOC_TOKENS.redirectService) redirectService: IRedirectService,
    @inject(IOC_TOKENS.storageService) storageService: IStorageService
  ) {
    super(toastStore);
    this.repository = repository;
    this.redirectService = redirectService;
    this.storageService = storageService;

    makeObservable(this, {
      logged: observable,
      accessToken: observable,
      bearerToken: computed,
      loginMode: observable,
      authForm: observable,
      setLoginMode: action,
      resetAuthForm: action,
      exchangeSocialAuthCode: action,
      logout: action,
      // @ts-ignore
      proceedToken: action,
      // setLogged: action,
      setAuthForm: action,
      // hydrate: action,
      // timeString: computed,
    });

    this.checkIfUserAuthorized();

    this.reactionDisposer = reaction(
      () => this.logged,
      (logged) => {
        // close the navbar mobile menu automatically on opened bsheet
        if (logged) {
          // check if onboarding model exists and completed
          /**
           * TODO: check if it's really necessary. Should add unittest
           * to check if a problem really exists
           *
           * should re-create a client on every login
           * prevent an issue of the duplicate
           * cache data on multiple login/logout chain
           * at the same session
           */
          urqlClientService.reCreateClient();
        }
      }
    );
  }

  private checkIfUserAuthorized() {
    // check if the cookie way awailable
    const token = this.storageService.getCookie(
      StorageDataKeysEnum.ACCESS_TOKEN
    );
    if (token) {
      this.proceedToken(token);
    } else {
      // TODO: maybe should just use this one way and remove the cookie at all?
      // but need to check if it work fine on dev and prod

      // if there is no any cookie so maybe it's hhtpOnly cookie on production. Doing the last chance to check it by the serv
      this.repository.checkIsUserLoggedIn().then((logged) => {
        if (logged !== this.logged) {
          runInAction(() => {
            this.logged = true;
          });
        }
      });
    }
  }

  private proceedToken = (token: string) => {
    console.info('token ->> ', token);
    this.accessToken = token;
    this.logged = true;

    /**
     * @info The access token cookie will be used in cross-site
     * session within iframes for third-party integrations like
     * wordpress etc. Web standards requires this cookie needs
     * to be secured for cross-site access.
     *
     */
    this.storageService.setCookie(StorageDataKeysEnum.ACCESS_TOKEN, token);
  };

  exchangeSocialAuthCode = async (code: string) => {
    const { data, error } = await this.repository.exchangeSocialAuthCode(code);

    const token = data;
    // success auth token
    if (token) {
      this.proceedToken(token);
    } else {
      this.handleError('Auth error', 'No token received');
    }

    return Boolean(error);
  };

  signInViaEmail = async (email: string) => {
    try {
      await this.repository.signInViaEmail(email);
      this.setAuthForm({
        ...this.authForm,
        completed: true,
      });
    } catch (e) {
      console.error('error sign IN');
    }
  };
  signUpViaEmail = async (email: string) => {
    try {
      await this.repository.signUpViaEmail(email);
      this.setAuthForm({
        ...this.authForm,
        completed: true,
      });
    } catch (e) {
      console.error('error sign Up');
    }
  };

  verifySignIn = async (otp: string) => {
    const { error, data } = await this.repository.verifySignIn(otp);
    const isError = Boolean(error);

    if (data?.token) {
      this.proceedToken(data.token);
    } else {
      this.handleError('Auth error', 'No token received');
    }

    return isError;
  };

  verifySignUp = async (otp: string) => {
    const { error, data } = await this.repository.verifySignUp(otp);
    const isError = Boolean(error);

    if (data?.token) {
      this.proceedToken(data.token);
    }

    return isError;
  };

  logout = () => {
    this.accessToken = null;
    // this.setLogged(false);
    this.logged = false;
    this.storageService.removeCookie(StorageDataKeysEnum.ACCESS_TOKEN);
  };

  setLoginMode = (loginMode: boolean) => {
    this.loginMode = loginMode;
  };
  resetAuthForm = () => {
    this.authForm = initialAuthForm;
  };
  setAuthForm = (authForm: typeof initialAuthForm) => {
    this.authForm = authForm;
  };

  /**
   * should use it to save the origin URL of the AUTH execution
   * to later redirect the user to this origin after success authentication
   */
  saveAuthCallerUrl = (
    reason: RedirectionIdsEnum.JOIN_COMMUNITY | RedirectionIdsEnum.AUTH_REQUIRED
  ) => {
    localStorage.setItem(
      AUTH_CALLER_URL,
      JSON.stringify({
        url: document.location.href,
        reason: reason,
      })
    );
  };

  proceedAuthCallerUrlFromStorage() {
    if (typeof window === 'undefined') return false;
    // TODO: localstorage service
    const authCallerUrlData =
      this.storageService.getStorageItem(AUTH_CALLER_URL);

    if (authCallerUrlData) {
      const { url, reason } = JSON.parse(authCallerUrlData);

      // should clear the saved url as the user will be redirected anyway
      this.removeAuthCallerUrlFromStorage();

      // means it's just a home page so no need to redirect
      if (window.document.location.href === url) {
        return false;
      }

      // there is no action by default, only auto-redirect back
      let urlWithExecuteAction = url;

      if (reason === RedirectionIdsEnum.JOIN_COMMUNITY) {
        urlWithExecuteAction = this.redirectService.generateQueryUrl(
          url,
          RedirectionURLQueriesEnum.SCHEDULED_ACTIONS,
          RedirectionURLActionsEnum.JOIN
        );
      }

      // TODO: implement UI for the user with redirection notify
      this.redirectService.redirect(urlWithExecuteAction, {
        self: true,
      });
    }

    return Boolean(authCallerUrlData);
  }

  removeAuthCallerUrlFromStorage() {
    // TODO: localstorage service
    localStorage.removeItem(AUTH_CALLER_URL);
  }
}
