import { DocumentNode } from 'graphql';
import { Client, OperationResult, OperationResultSource } from 'urql';
import { Source, pipe, subscribe } from 'wonka';

export type SubscribeCallback = (queryResponse: any) => void;

export type OperatorOptiions = {
  queryName: string;
  gqlQuery: DocumentNode;
  variables: any;
  subscriber: (data: any) => void;
};

export type SubscribeRegistry = {
  [uniqueQueryName: string]: {
    variables: { [index: string]: any };
    unsubscribe: () => void;
    // stream source
    source: OperationResultSource<OperationResult> | Source<OperationResult>;
  };
};

export class GraphQLSubscription {
  // TODO: How to provide generic for nested dynamic objects?
  registry: SubscribeRegistry = {};

  subscribe = (params: {
    queryName: string;
    queryStream: OperationResultSource<OperationResult>;
    subscriber: SubscribeCallback;
    variables: { [index: string]: any };
  }) => {
    const { queryName, queryStream, subscriber, variables } = params;
    const existingSubscription = this.registry[queryName];
    // create new subscription if there is no one
    if (!existingSubscription) {
      const { unsubscribe } = pipe(queryStream, subscribe(subscriber));

      this.registry[queryName] = {
        variables,
        source: queryStream,
        unsubscribe: unsubscribe,
      };
    }
  };

  getSubscriptionByName = (queryName: string) => {
    return this.registry[queryName];
  };

  unsubscribe = (queryName: string) => {
    const querySubscription = this.registry[queryName];
    if (!querySubscription) {
      // for debug purpose
      console.error(
        'SUBSIBSCRIBE: There is no subscription for this query: ',
        queryName
      );
      return;
    }

    querySubscription.unsubscribe();

    delete this.registry[queryName];
  };

  /**
   * creates a new subscription if it isn't exist or
   * destroys the previous one and creates a new one
   * @param gqlClient - now it's a urql client
   * @param params
   */
  createSubscription(
    gqlClient: Client,
    params: {
      /** unique query name that will be used as key in subs registry */
      QUERY_NAME: string;
      /** graphql query to subscribe */
      gqlQuery: DocumentNode;
      /** variables that should be passed to the query */
      variables: { [index: string]: any };
      /** executes only when the prev subscription is existing and
       * need to check if there should be created a new subscription.
       * Receives variables from the existing subscription and should check
       * if the conditional criteria requires to create a new subscription
       */
      getNewQueryValidator: (prevVariables: any) => boolean;
      /** will be called before the new subscription creation. Useful for loaders state */
      beforeSubscriptionCb?: () => void;
      /** a query subscriber */
      subscriber: (data: any) => void;
    }
  ) {
    const {
      QUERY_NAME,
      gqlQuery,
      variables,
      getNewQueryValidator,
      beforeSubscriptionCb,
      subscriber,
    } = params;
    const prevSubscription = this.getSubscriptionByName(QUERY_NAME);

    if (!prevSubscription) {
      beforeSubscriptionCb?.();
      this.createQuerySubscription(gqlClient, {
        queryName: QUERY_NAME,
        gqlQuery,
        variables,
        subscriber,
      });
    } else if (getNewQueryValidator(prevSubscription.variables)) {
      this.mergePages(gqlClient, {
        subscriber,
        gqlQuery,
        variables,
        queryName: QUERY_NAME,
      });
    }
  }

  private mergePages(gqlClient: Client, params: OperatorOptiions) {
    const { subscriber, gqlQuery, variables, queryName } = params;
    // TODO: investigate how to correctly imeratively merge sources for multiple pages. I haven't found any performance way yet
    const newPageSource = gqlClient.query(gqlQuery, variables);
    const prevQuery = this.registry[queryName];

    this.registry[queryName].variables = variables;

    prevQuery.unsubscribe();

    const { unsubscribe } = pipe(newPageSource, subscribe(subscriber));

    this.registry[queryName] = {
      variables,
      unsubscribe,
      source: newPageSource,
    };
  }

  // TODO: maybe move it to the API base?
  // cause it's generic and will be for the all repos
  private createQuerySubscription(gqlClient: Client, params: OperatorOptiions) {
    const { queryName, gqlQuery, variables, subscriber } = params;
    const queryStream = gqlClient.query(gqlQuery, variables);

    this.subscribe({
      queryName: queryName,
      queryStream,
      variables: variables,
      subscriber,
    });
  }
}
