/**
 * Module defined for URQL configuration.
 * url defined for server is our local graphql server to test out urql workings.
 * All other schemas, resolvers, optimistic key values
 * are being worked as per the local graphql server(localGraphql.config file).
 */
// for now it isn't so useful to enable it
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import {
  Client,
  createClient,
  errorExchange,
  fetchExchange,
  subscriptionExchange,
} from 'urql';

import { relayPagination } from '@urql/exchange-graphcache/extras';

import {
  ChannelPayloadEdge,
  Role_Levels,
} from '@10x/foundation/types/graphql-schema';
import { Cache, cacheExchange } from '@urql/exchange-graphcache';

import { createClient as createWSClient } from 'graphql-ws';
import cookie from 'js-cookie';
import {
  GET_CHANNEL_MESSAGES,
  GET_COMMUNITY_BY_SLUG_NAME,
  GET_CURRENT_USER_DATA,
  GET_USER_COMMUNITIES,
} from '../graphql/queries';

import { eventBus, GlobalEvents } from '@mainApp/src/events';
import { StorageDataKeysEnum } from '../services/types';
import { SYSTEM_VARIABLES } from './system.config';

// import { filter, map, merge, pipe } from 'wonka';
// const noopExchange = ({ client, forward }: any) => {
//   return (operations$: any) => {
//     const queries = pipe(
//       operations$,
//       filter((op: any) => op.kind === 'query'),
//       map((op: any) => {
//         if (op.query.definitions[0].name.value !== 'GetChannelMessages') {
//           return op;
//         }

//         const querySelectionSet = op.query.definitions[0].selectionSet;
//         const edgesSet =
//           querySelectionSet.selections[0].selectionSet.selections[4];
//         const nodeSet = edgesSet.selectionSet.selections[1];
//         const attachmentsSet = nodeSet.selectionSet.selections[11];
//         // remove the loading field
//         const clearedFields = attachmentsSet.selectionSet.selections.filter(
//           (o: any) => o.name.value !== 'loading'
//         );

//         attachmentsSet.selectionSet.selections = clearedFields;
//         return op;
//       })
//     );

//     const rest = pipe(
//       operations$,
//       filter((op: any) => op.kind !== 'query')
//     );
//     return forward(merge([queries, rest]));

//     /**
//      * query.definitions.name.value = "GetChannelMessages"
//      *
//      */

//     // const rest = pipe(
//     //   operations$,
//     //   filter(op => op.kind !== 'query')
//     // );

//     // // <-- The ExchangeIO function
//     // // We receive a stream of Operations from `cacheExchange` which
//     // // we can modify before...
//     // const forwardOperations$ = operations$;

//     // // ...calling `forward` with the modified stream. The `forward`
//     // // function is the next exchange's `ExchangeIO` function, in this
//     // // case `fetchExchange`.
//     // const operationResult$ = forward(operations$);

//     // // We get back `fetchExchange`'s stream of results, which we can
//     // // also change before returning, which is what `cacheExchange`
//     // // will receive when calling `forward`.
//     // return operationResult$;
//   };
// };
const url =
  process.env.NEXT_PUBLIC_GRAPHQL_URI || 'http://localhost:3002/graphql';
const subscriptionUrl =
  process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URI || 'ws://localhost:3002/graphql';
if (!url) {
  throw new Error('Please, provide the NEXT_PUBLIC_GRAPHQL_URI env variable!');
}
if (!subscriptionUrl) {
  throw new Error(
    'Please, provide the NEXT_PUBLIC_SUBSCRIPTIONS_URI env variable!'
  );
}

// Create a WebSocket client for subscriptions
// const subscriptionClient = new SubscriptionClient(subscriptionUrl, {
//   reconnect: true,
// });
// const subscriptionClient = new SubscriptionClient(subscriptionUrl, {
//   reconnect: true,
// });

// const wsClient = createWSClient(subscriptionClient);

type ChannelMessageArgs = {
  communityId: string;
  channelId: string;
  id: string;
};

function getActualMessageQueryData(cache: Cache, args: ChannelMessageArgs) {
  return cache
    .inspectFields({ __typename: 'Query' })
    .filter((field) => field.fieldName === 'channelMessages')
    .filter((field) => {
      const isSameCommunity =
        args.communityId === field?.arguments?.communityId;
      const isSameChannel = args.channelId === field?.arguments?.channelId;
      return isSameChannel && isSameCommunity;
    });
}

const clientConfig = {
  // TODO: env
  url: url,
  // url: 'http://localhost:5555',

  exchanges: [
    devtoolsExchange,
    errorExchange({
      onError(combinedError) {
        eventBus.emit(GlobalEvents.URQL_GLOBAL_ERROR, combinedError);
        // const toastStore = iocContainer.get<IToastStore>(IOC_TOKENS.toastStore);

        // // in the case of cors error there is no response from server
        // // and the only deafult graphql `Failed to fetch` message
        // if (!combinedError.response && combinedError.networkError) {
        //   toastStore.show({
        //     type: ToastTypesEnum.ERROR,
        //     title: 'CORS Error',
        //     content: 'Failed to fetch. Looks like it is a CORS error',
        //   });
        //   return;
        // }

        // const error = combinedError?.graphQLErrors[0];
        // toastStore.show({
        //   type: ToastTypesEnum.ERROR,
        //   title: combinedError.name,
        //   content: error ? error.message : combinedError.message,
        // });
      },
    }),

    // noopExchange,

    cacheExchange({
      // TODO: disabled it because message.parent query selection caused error
      // schema: introspectionSchema,

      keys: {
        CommunityColorPropertiesPayload: () => null,
        CommunityColorPayload: () => null,
        ChannelHeatOptionPayload: () => null,
        ChannelPaginationPayload: () => null,
        ChannelOptionPayload: () => null,
        ChannelNotificationCountPayload: () => null,
        ChannelGroupPaginatedPayload: () => null,
        FileMetadataPayload: () => null,
      },

      resolvers: {
        Query: {
          userCommunities: relayPagination({
            mergeMode: 'outwards',
          }),
          //   channelMessages: relayPagination({
          //     mergeMode: 'inwards',
          //   }),
          // },
          // ChannelMessageAttachmentPayload: {
          //   loading: (parent, _args, cache, info) => {
          //     return true;
          //   },
        },
      },

      updates: {
        Mutation: {
          joinCommunity: (result, _args, cache) => {
            const community = result.joinCommunity;
            const userData = cache.readQuery({
              query: GET_CURRENT_USER_DATA,
            });

            cache.updateQuery(
              {
                query: GET_USER_COMMUNITIES,
                variables: {
                  ownerId: userData.me.id,
                },
              },
              (data) => {
                if (!data) return null;

                const edgeNode = {
                  node: community,
                  __typename: 'CommunityEdge',
                };
                data.userCommunities.edges.push(edgeNode);

                return data;
              }
            );
          },
          leaveCommunity: (result, _args, cache) => {
            const community: any = result.leaveCommunity;

            const userData = cache.readQuery({
              query: GET_CURRENT_USER_DATA,
            });

            cache.updateQuery(
              {
                query: GET_USER_COMMUNITIES,
                variables: {
                  ownerId: userData.me.id,
                },
              },
              (data) => {
                if (!data) return null;
                const filteredUserCommunities =
                  data.userCommunities.edges.filter((edge: any) => {
                    return edge.node.id !== community?.id;
                  });

                data.userCommunities.edges = filteredUserCommunities;

                return data;
              }
            );
          },
          createChannelMessage: (result, args, cache, _info) => {
            const newMessage = result.createChannelMessage;

            const messagesData = getActualMessageQueryData(
              cache,
              args as ChannelMessageArgs
            );

            for (const messagesQueryData of messagesData) {
              cache.updateQuery(
                {
                  query: GET_CHANNEL_MESSAGES,
                  variables: messagesQueryData.arguments as any,
                },
                (data) => {
                  // TODO: after the scroll to new page, message remove and the message add this event could happens twice but the data will be null. Workaround. Need to investigate it further. Probably it's a subscriptions merge issue?
                  if (!data) return;

                  const newMessageEdge = {
                    node: newMessage,
                    __typename: 'MessagePayloadEdge',
                  };
                  data?.channelMessages.edges.unshift(newMessageEdge);

                  data.channelMessages.pageInfo.count++;
                  return data;
                }
              );
            }
          },

          deleteChannelMessage: (_result, args, cache) => {
            // Test. just invalidate to make an request and sync with the server automatically
            const messagesData = getActualMessageQueryData(
              cache,
              args as ChannelMessageArgs
            );

            for (const messagesQueryData of messagesData) {
              cache.updateQuery(
                {
                  query: GET_CHANNEL_MESSAGES,
                  variables: messagesQueryData.arguments as any,
                },
                (data) => {
                  // TODO: after the scroll to new page, add new message, message remove this event could happens twice but the data will be null. Workaround. Need to investigate it further. Probably it's a subscriptions merge issue?
                  if (!data) return;

                  const updatedEdges = data?.channelMessages.edges.filter(
                    (edge: ChannelPayloadEdge) => {
                      return edge.node.id !== args.id;
                    }
                  );

                  data.channelMessages.edges = updatedEdges;

                  // TODO. There is no update of the end/start cursor. Maybe should do it?
                  data.channelMessages.pageInfo.count--;

                  return data;
                }
              );
            }
          },
        },
        Subscription: {
          channelMessages: (result, _args, cache, _info) => {
            const {
              // @ts-ignore
              action,
              // @ts-ignore
              payload,
            } = result.channelMessages;

            const message = payload.node;

            const userData = cache.readQuery({
              query: GET_CURRENT_USER_DATA,
            });

            if (userData.me.id === message.author.id) {
              return;
            }

            // const messagesData = cache
            //   .inspectFields({ __typename: 'Query' })
            //   .filter((field) => field.fieldName === 'channelMessages');

            if (action === 'CREATED') {
              eventBus.emit(GlobalEvents.MESSAGE_SUBSCRIPTION_CREATED, message);
              // TODO: maybe remove it at all as I'm trying to use custom update?

              // for (const messagesQueryData of messagesData) {
              //   cache.updateQuery(
              //     {
              //       query: GET_CHANNEL_MESSAGES,
              //       variables: messagesQueryData.arguments as any,
              //     },
              //     (data) => {
              //       const newMessageEdge = {
              //         node: message,
              //         __typename: 'MessagePayloadEdge',
              //       };
              //       data?.channelMessages.edges.unshift(newMessageEdge);

              //       data.channelMessages.pageInfo.count++;
              //       return data;
              //     }
              //   );
              // }
            } else if (action === 'DELETED') {
              eventBus.emit(GlobalEvents.MESSAGE_SUBSCRIPTION_DELETED, message);
              // for (const messagesQueryData of messagesData) {
              //   cache.updateQuery(
              //     {
              //       query: GET_CHANNEL_MESSAGES,
              //       variables: messagesQueryData.arguments as any,
              //     },
              //     (data) => {
              //       const updatedEdges = data?.channelMessages.edges.filter(
              //         (edge: ChannelPayloadEdge) => {
              //           return edge.node.id !== message.id;
              //         }
              //       );

              //       data.channelMessages.edges = updatedEdges;
              //       // TODO. There is no update of the end/start cursor. Maybe should do it?
              //       data.channelMessages.pageInfo.count--;

              //       return data;
              //     }
              //   );
              // }
            } else if (action === 'UPDATED') {
              eventBus.emit(GlobalEvents.MESSAGE_SUBSCRIPTION_UPDATED, message);
            }
          },
        },
      },

      optimistic: {
        joinCommunity(_args, cache, info: any) {
          const slugName = info.variables.extra.slugName;

          const cacheData = cache.readQuery({
            query: GET_COMMUNITY_BY_SLUG_NAME,
            variables: {
              slugName: slugName,
            },
          });

          const community = cacheData.communityBySlugName;

          const optimisticCommunity = {
            ...community,
            members: {
              ...community.members,
              totalCount: community.members.totalCount + 1,
            },
            onlineMembers: {
              ...community.members,
              totalCount: community.onlineMembers.totalCount + 1,
            },
            userAssignedRoles: [Role_Levels.Member],
          };

          return optimisticCommunity;
        },
        leaveCommunity(_args, cache, info: any) {
          const slugName = info.variables.extra.slugName;
          const cacheData = cache.readQuery({
            query: GET_COMMUNITY_BY_SLUG_NAME,
            variables: {
              slugName: slugName,
            },
          });

          const community = cacheData.communityBySlugName;

          const optimisticCommunity = {
            ...community,
            members: {
              ...community.members,
              totalCount: community.members.totalCount - 1,
            },
            onlineMembers: {
              ...community.members,
              totalCount: community.onlineMembers.totalCount - 1,
            },
            userAssignedRoles: [Role_Levels.NonMember],
          };

          return optimisticCommunity;
        },

        // createChannelMessage(_args, cache, info: any) {
        //   const optimisticPayload = info.variables?.extra?.optimisticPayload;
        //   return optimisticPayload;
        // },
      },
    }),

    authExchange(async (utils: any) => {
      return {
        addAuthToOperation(operation: any) {
          // there are some opration that shouldn't use the auth token like oAuthExchangeCode
          const isWithoutAuthToken =
            operation.context[SYSTEM_VARIABLES.WITHOUT_AUTH_TOKEN];
          const token = cookie.get(StorageDataKeysEnum.ACCESS_TOKEN);

          if (!token || isWithoutAuthToken) {
            return operation;
          }
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },

        didAuthError(error: any, _operation: any) {
          // basically it's a duplication for the code below
          // unathorized
          if (error.response?.status === 401) {
            return true;
          }
          const context = _operation.context;

          // if this is a skipUnauthorizedEvent then it shouldn't be handled by unauthorized policy even if it should in the normal circumstances
          const isForbidden = context.skipUnauthorizedEvent
            ? false
            : error.graphQLErrors.some((e: any) => {
                return (
                  e.error === 'Unauthorized' ||
                  e.extensions.code === 'FORBIDDEN'
                );
              });
          // here could be triggered redirection to auth etc.
          return Boolean(isForbidden);
        },

        async refreshAuth() {
          eventBus.emit(GlobalEvents.URQL_AUTH_TOKEN_REFRESH);
        },
      };
    }),

    fetchExchange,
  ],
};

if (typeof window !== 'undefined') {
  // TODO: I've already fetched this token above so, maybe, just save it somewhere?
  const token = cookie.get('accessToken');
  const wsClient = createWSClient({
    url: subscriptionUrl,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${token}`, // Add any required headers
      },
    },
  });

  const subExchange = subscriptionExchange({
    forwardSubscription(request) {
      const input = { ...request, query: request.query || '' };
      return {
        subscribe(sink) {
          const unsubscribe = wsClient.subscribe(input, sink);
          return { unsubscribe };
        },
      };
    },
  });

  clientConfig.exchanges.push(subExchange);
}

type URQLClientServiceType = {
  _client: Client | null;
  client: Client | null;
  createInitialClient: () => Client;
  reCreateClient: () => Client;
};
// allows to re-create and save the client on logout/login in _app
// and the mobx store is able to reach the client. Check if it's needed in future
const urqlClientService: URQLClientServiceType = {
  _client: null,

  get client() {
    return this._client;
  },

  createInitialClient() {
    if (!this._client) {
      this._client = createClient(clientConfig) as any;
    }

    return this._client as Client;
  },

  reCreateClient() {
    this._client = createClient(clientConfig) as any;

    return this._client as Client;
  },
};

const urqlClient = urqlClientService.createInitialClient();

export { urqlClient, urqlClientService };
