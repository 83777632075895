import { RedirectionIdsEnum } from '@mainApp/src/services';
import { IAuthRepository } from '../../repositories';
export type InitialAuthForm = {
  show: boolean;
  email: string;
  processing: boolean;
  completed: boolean;
};

export type SocialAuthTypes = 'google' | 'facebook' | 'apple';
export interface IAuthStore {
  logged: boolean;
  accessToken: string | null;
  // a computed value `Bearer <accessToken>`
  bearerToken: string;
  loginMode: boolean;
  authForm: InitialAuthForm;

  repository: IAuthRepository; // Auth Repository

  logout: () => void;
  signInViaEmail: (email: string) => void;
  signUpViaEmail: (email: string) => void;
  verifySignUp: (otp: string) => Promise<boolean>;
  verifySignIn: (otp: string) => Promise<boolean>;
  setLoginMode: (loginMode: boolean) => void;
  // setLogged: (logged: boolean, token: string) => void;
  resetAuthForm: () => void;
  proceedAuthCallerUrlFromStorage: () => boolean;
  removeAuthCallerUrlFromStorage: () => void;
  saveAuthCallerUrl: (
    reason: RedirectionIdsEnum.JOIN_COMMUNITY | RedirectionIdsEnum.AUTH_REQUIRED
  ) => void;
  setAuthForm: (authForm: InitialAuthForm) => void;
  exchangeSocialAuthCode: (code: string) => Promise<boolean>;
}

export enum AuthOperationsEnum {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
}
