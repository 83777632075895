console.info(
  'process.env.NEXT_PUBLIC_SERVER_URI =>',
  process.env.NEXT_PUBLIC_SERVER_URI
);

export const paths = {
  get root() {
    return '';
    // return document?.location?.origin || '/';
  },
  get home() {
    return this.root;
  },
  contact: '/contact',
  login: '/login',
  signup: '/signup',
  pricing: '/pricing',
  pro: '/pro',
  explore: '/explore',
  // the backend url for the social auth stuff
  hostAuth: `${process.env.NEXT_PUBLIC_SERVER_URI}/auth`,
  getCommunityPath(communitySlugName: string) {
    return `${this.home}/${communitySlugName}`;
  },
  getChannelPath(communitySlugName: string, channelSlug: string) {
    return `${this.home}/${communitySlugName}/channel/${channelSlug}`;
  },
  support:
    'https://10xteam.notion.site/10X-Support-Center-c79ff197c5354ebeb6ecb1e201444089',
  terms: 'https://10xteam.notion.site/Terms-b308e06d90364119b4d5c34ed054f227',
  privacy:
    'https://10xteam.notion.site/Privacy-ebc17477727645398c75a4ff95a50634',
  guidelines:
    'https://10xteam.notion.site/Community-Guidelines-cdb202487d4f48a0925ca8c6f425de26',
};

export const protectedPaths = [paths.contact];
